import crudGetters from "@/store/templates/crud/getters";

export default {
  rejectReason: state => state.model,
  id: state => state.model.id,
  name: state => state.model.name,
  mailTemplate: state => state.model.mailTemplate,
  isSoftRejectable: state => state.model.isSoftRejectable,
  isSoftRejectCustomizable: state => state.model.isSoftRejectCustomizable,
  softRejectCustomization: state => state.model.softRejectCustomization,
  ...crudGetters
};
