import initialCrudState from "@/store/templates/crud/state";

const initialModel = () => ({
  id: null,
  name: null,
  mailTemplate: null,
  isSoftRejectable: false,
  isSoftRejectCustomizable: false,
  softRejectCustomization: null
});

export default () => ({
  ...initialCrudState(initialModel)
});
