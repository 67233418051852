import crudMutations from "@/store/templates/crud/mutations";

export default {
  setName(state, name) {
    state.model.name = name;
  },
  setMailTemplate(state, mailTemplate) {
    state.model.mailTemplate = mailTemplate;
  },
  setIsSoftRejectable(state, isSoftRejectable) {
    state.model.isSoftRejectable = isSoftRejectable;
  },
  setIsSoftRejectCustomizable(state, isSoftRejectCustomizable) {
    state.model.isSoftRejectCustomizable = isSoftRejectCustomizable;
  },
  setSoftRejectCustomization(state, softRejectCustomization) {
    state.model.softRejectCustomization = softRejectCustomization;
  },
  ...crudMutations
};
